<ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/" routerLinkActive="active">Início</a></li>
    <li class="breadcrumb-item active">Resultados para o termo "{{termo}}"</li>
</ol>
<div class="container">
    <div class="resultados">
        <!-- <h3>Resultados para <b>"{{termo}}"</b></h3> -->
        <div class="resultado" *ngFor="let r of retorno" (click)="faq(r.faq_id)">
            <div class="assunto">{{r.faq_assunto}}</div>
            <div class="titulo">{{r.faq_titulo}}</div>
            <!-- <div class="conteudo">{{r.faq_conteudo | slice:0:40}}</div> -->
        </div>
    </div>
</div>
