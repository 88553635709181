<h1 style="color:#a62d2e; text-align: left;margin-top:30px;font-weight: lighter;">
    Perguntas Frequentes
</h1>
<br>
<div class="row">
    <div class="col-md-4">
        <h2 style="color: #a62d2e;border-bottom: 1px solid;">
            Geral
        </h2>
        <br>
        <div class="table-responsive">
            <tr *ngFor="let result of retorno.geral">
                <td>
                    <p><a routerLink="/marca" (click)="teste(result.faq_id)" routerLinkActive="active" style="color:dimgray;"> {{result.faq_titulo}} </a></p>
                </td>
                <br>
            </tr>
            <br>
            <p><a routerLink="/lista/Geral" routerLinkActive="active" style="color:darkgray;">Ver todas as Perguntas</a></p>
        </div>
    </div>
    <div class="col-md-4">
        <h2 style="color: #a62d2e;border-bottom: 1px solid;">
            Saúde
        </h2>
        <br>
        <div class="table-responsive">
            <tr *ngFor="let result of retorno.saude">
                <td>
                    <p><a routerLink="/marca" (click)="teste(result.faq_id)" routerLinkActive="active" style="color:dimgray;"> {{result.faq_titulo}} </a></p>
                </td>
                <br>
            </tr>
            <br>
            <p><a routerLink="/lista/Saúde" routerLinkActive="active" style="color:darkgray;">Ver todas as Perguntas</a></p>
        </div>
    </div>
    <div class="col-md-4">
        <h2 style="color: #a62d2e;border-bottom: 1px solid;">
            Previdência
        </h2>
        <br>
        <div class="table-responsive">

            <div class="table-responsive">
                <tr *ngFor="let result of retorno.previdencia">
                    <td>
                        <p><a routerLink="/marca" (click)="teste(result.faq_id)" routerLinkActive="active" style="color:dimgray;"> {{result.faq_titulo}} </a></p>
                    </td>
                    <br>
                </tr>
                <br>
                <p><a routerLink="/lista/Previdência" routerLinkActive="active" style="color:darkgray;">Ver todas as Perguntas</a></p>
            </div>
        </div>
    </div>
</div>
<div class="faleconosco">
  <h3>Se você não solucionou sua dúvida aqui, envie um <a href="http://ipsm.mg.gov.br/conteudo.asp?pagina=fale_conosco" target="_blank">Fale Conosco</a></h3>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
