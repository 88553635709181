<ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/" routerLinkActive="active">Início</a></li>
    <li class="breadcrumb-item"><a routerLink="/lista/{{dados.assunto}}" routerLinkActive="active">{{dados.assunto}}</a></li>
    <li class="breadcrumb-item active">{{dados.titulo}}</li>
</ol>
<br>
<div class="mx-auto" style="width: 700px;">
    <h3 style="color:#a62d2e; text-align: left">
        {{dados.titulo}}
    </h3>

    <br>
    <br>
    <div [innerHTML]="html"></div>
    <br>
    <br>
    <div style="border-top: 1px solid #CCCCCC; padding:20px 12px;"></div>
    <div data-toggle="collapse" data-target="#collapseExample" *ngIf="!isValid" aria-expanded="true" aria-controls="collapseExample">
        <p><a style="color:dimgray;font-size:15px;">Esse artigo foi útil?</a>
            <button type="button" class="btn btn-info" (click)="util(1)" style="margin-left: 378px; width: 70px;">Sim</button>
            <button type="button" class="btn btn-info" (click)="util(0)" style="margin-left: 30px; width: 70px;">Não</button></p>
    </div>
    <div *ngIf="isValid">
        <div class="alert alert-success alert-dismissible fade show" role="alert">
            <strong>O IPSM agradece a sua opinião!</strong>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
        </div>
    </div>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
    <br><br><br><br><br><br><br>
