import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MarcaComponent } from './marca/marca.component';
import { GerarFAQComponent } from './gerar-faq/gerar-faq.component';
import { ListaFaq } from './lista-faq/lista-faq';
import { ListaResultado } from './lista-resultado/lista-resultado';



const APP_ROUTES: Routes = [

    { path: 'lista/:assunto', component: ListaFaq },
    { path: 'lista/resultados/:termo', component: ListaResultado },
    { path: 'marca/:id', component: MarcaComponent},
    { path: 'gerar-faq', component: GerarFAQComponent},
    { path: '', component: HomeComponent }

]

@NgModule({
    imports: [RouterModule.forRoot(APP_ROUTES)],
    exports: [RouterModule]
    })
    export class AppRoutingModule { }
