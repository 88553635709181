import { Component } from '@angular/core';
import { FaqService } from '../services/faq.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';


@Component({
  selector: 'app-testando',
  templateUrl: './lista-faq.html',
  styleUrls: ['./lista-faq.css']
})


export class ListaFaq {
  public loading = false;
  api
  retorno
  data = [];
  searchText
  assunto
  host
  p: number = 1;


  isValid: boolean = true;
  changeValue(valid: boolean) {
    this.isValid = valid;
  }

  constructor(private service: FaqService, private route: ActivatedRoute, private router: Router) {

    this.loading = true
    this.host = this.service.host
    this.updateUrl()
  }
  async updateUrl() {
    this.router.events.subscribe(val => {
      this.route.params.subscribe(async params => {
        this.assunto = params['assunto'];
        this.retorno = await this.service.get(`${this.host}/faq/assunto/${this.assunto}`)
        this.loading = false
      });
    });
  }

  faq(id){
    this.router.navigate(['marca/' + id])
  }
}

