import { Component } from '@angular/core';
import { FaqService } from '../services/faq.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from "@angular/common";
import { StyleCompiler } from '@angular/compiler';

@Component({
  selector: 'app-marca',
  templateUrl: './marca.component.html',
  styleUrls: ['./marca.component.css']
})
export class MarcaComponent {

  public loading = false;
  isValid: boolean = false;

  ngOnInit() {


  }

  dados = {
    titulo: '',
    conteudo: '',
    assunto: '',
    id: ''
  }

  searchText
  api
  api2
  retorno
  retry
  data = [];
  html;
  id

  public userId;

  constructor(private sanitizer: DomSanitizer, private service: FaqService, private route: ActivatedRoute, private router: Router, private location: Location) {
    this.api = this.service.host + "/faq/receive";
    this.api2 = this.service.host + "/faq/receiveidfaq";
    this.route.params.subscribe(params => this.userId = params['id']);

    // verifica os eventos da url e aciona o getFaq
    this.router.events.subscribe(val => {
      this.loading = true
      this.route.params.subscribe(params => {
        this.id = params['id'];
      });
      this.getFaq()
    });
  }

  async getFaq(): Promise<any> {

    const retorno = await this.service.post(this.api2, { faqid: this.userId })
    this.retry = retorno[0]
    this.html = this.sanitizer.bypassSecurityTrustHtml(this.retry.faq_conteudo);
    this.dados.titulo = this.retry.faq_titulo
    this.dados.conteudo = this.retry.faq_conteudo
    this.dados.assunto = this.retry.faq_assunto
    this.dados.id = this.retry.faq_id

    this.loading = false;
  }

  async util(resposta) {
    this.isValid = true
    if (resposta === 1) {
      const retorno = await this.service.get(`${this.service.host}/faq/util/${this.dados.id}`)
      console.log(retorno)
    }


  }
  keyword = 'faq_titulo';
}



