<ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/" routerLinkActive="active">Início</a></li>
    <li class="breadcrumb-item active">Todas as perguntas de {{assunto}}</li>
</ol>
<div class="list-group">
    <a href="javascript:;" class="list-group-item active titulo-lista">
    {{assunto}}
  </a>
    <button type="button" class="list-group-item" style="text-align:left" *ngFor="let result of retorno | paginate: { itemsPerPage: 25, currentPage: p }" (click)="faq(result.faq_id)">{{result.faq_titulo}}</button>
</div>
<br>
<pagination-controls (pageChange)="p = $event" maxSize="9" directionLinks="true" autoHide="true" responsive="true" previousLabel="Anterior" nextLabel="Próxima" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page">
</pagination-controls>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
