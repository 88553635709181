import { Component } from '@angular/core';
import { FaqService } from './services/faq.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { getMaxListeners } from 'process';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {


  api
  retorno
  data = [];
  public searchText
  pesquisa
  resultado_pesquisa
  exibir_lista = false
  not_found = false

  constructor(private service: FaqService, private route: Router) {
    this.api = this.service.host + "/faq/receive";
    this.getFaq()

  }

  async getFaq(): Promise<any> {
    this.retorno = await this.service.post(this.api, { titulo: "1" })
  }

  async buscar() {

    if (this.pesquisa.length >= 3) {
      this.exibir_lista = true
      this.resultado_pesquisa = await this.service.post(`${this.service.host}/faq/lista/resultados`, { termo: this.pesquisa })
      if (this.resultado_pesquisa.length === 0) {
        this.not_found = true
      } else {
        this.not_found = false
      }
    } else {
      this.resultado_pesquisa = []
      this.exibir_lista = false
    }


  }

  search(event) {
    this.resultados()
  }

  async resultados() {
    if (!this.pesquisa) {
      this.pesquisa = 'exibir todos';
    }
    this.exibir_lista = false
    this.route.navigate(['lista/resultados/' + this.pesquisa])
    this.limpar()
  }
  limpar() {
    this.pesquisa = ''
  }

  onBlurMethod() {
    this.exibir_lista = false
  }

  selectFaq(id, titulo) {
    console.log('aqui')
    this.route.navigate(['marca/' + id])
    this.pesquisa = '';
    this.exibir_lista = false;

  }

  fecharLista(){
    this.exibir_lista = false;
    this.pesquisa = '';
  }


  async disableList() {

  }

  title = 'primeiro-projeto';
  public mostraTitulo = false;
  keyword = 'faq_titulo';

  selectEvent(item) { }

  onChangeSearch(search: string) { }

  onFocused(e) { }

}
