import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FaqService {

  private prod = 'prod';
  public host
  public conexao

  constructor(private http: HttpClient) {

    if (this.prod == 'local') {
      this.host = 'http://localhost:3000/api-faq'
    } else if (this.prod == 'dev') {
      this.host = 'http://10.49.4.87:3000/api-faq'
    } else {
      this.host = 'https://api.ipsm.gov.br/api-faq'
    }
  }

  async post(url, data): Promise<any> {
    return this.http.post(url, data,).toPromise()
  }

  post2(url, data): Observable<any> {
    return this.http.post(url, data,)
  }

  get(url): Promise<any> {
    return this.http.get(url).toPromise()
  }

}
export class DataService {

}
