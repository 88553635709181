import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'cfaq'
})
export class CfaqPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): any {

    const obj = []

    if (!args){
      return value
      
    }

  
    for(const i in value){

      if(value[i].faq_id == args){

        obj.push(value[i])
      }

    }

    return(obj)
   
  }


}
