<h1 style="color:#a62d2e; text-align: center">    
    Criar novos FAQ's
</h1>
<form>   
    <div class="center-me">
    <div class="form-group center-me">
      <label for="exampleFormControlSelect1" style="color:#a62d2e; font-size: 0.5cm;">Seção:</label>
      <select class="form-control" style="background-color:lightsteelblue; width: 600px;" id="exampleFormControlSelect1">
        <option selected>Selecione...</option>
        <option>Geral</option>
        <option>Saúde</option>
        <option>Previdência</option>
      </select>
    </div>
    <br>
    <div class="form-group">
        <label for="exampleFormControlInput1" style="color:#a62d2e; font-size: 0.5cm;">Título:</label>
        <input type="text" style="background-color:lightsteelblue; width: 600px;" class="form-control" id="exampleFormControlInput1">
      </div>
             <div class="form-group">
      <label for="exampleFormControlTextarea1" style="color:#a62d2e; font-size: 0.5cm;">Resumo:</label>
      <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" style="background-color:lightsteelblue; width: 600px;"></textarea>
    </div>
</div> 
<a style="color:#a62d2e; font-size: 0.5cm;">Escolha a imagem se necessário:</a>
<p></p>
    <div class="file-field">
         <p><input style="background-color: lightsteelblue; width: 600px;" type="file"> </div>
            <div class="form-group">
                <label for="exampleFormControlInput1" style="color:#a62d2e; font-size: 0.5cm;">Título tópico:</label>
                <input type="text" style="background-color:lightsteelblue; width: 600px;" class="form-control" id="exampleFormControlInput1">
              </div>
                     <div class="form-group">
              <label for="exampleFormControlTextarea1" style="color:#a62d2e; font-size: 0.5cm;">Resumo tópico:</label>
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" style="background-color:lightsteelblue; width: 600px;"></textarea>
            </div>
            <a style="color:#a62d2e; font-size: 0.5cm;">Escolha a imagem do tópico se necessário:</a>
<p></p>
    <div class="file-field">
         <p><input style="background-color: lightsteelblue; width: 600px;" type="file"> </div>
            <div *ngIf="isValid">
            <button type="button" class="btn btn-info" (click)= "changeValue(false)" >Adicionar novo tópico</button>  
        </div>
    
            <div *ngIf="!isValid">
                   <div class="form-group">
                       <label for="exampleFormControlInput1" style="color:#a62d2e; font-size: 0.5cm;">Título tópico 2:</label>
                       <input type="text" style="background-color:lightsteelblue; width: 600px;" class="form-control" id="exampleFormControlInput1">
                     </div>
                            <div class="form-group">
                     <label for="exampleFormControlTextarea1" style="color:#a62d2e; font-size: 0.5cm;">Resumo tópico 2:</label>
                     <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" style="background-color:lightsteelblue; width: 600px;"></textarea>
                   </div>
                   <a style="color:#a62d2e; font-size: 0.5cm;">Escolha a imagem do tópico 2 se necessário:</a>
                   <p></p>
    <div class="file-field">
         <p><input style="background-color: lightsteelblue; width: 600px;" type="file"> </div>
       <p></p></div>
       <br>


       <button type="button" class="btn btn-info" (click)= "changeValue(false)" >Gravar</button>  
  </form>
  
  