import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assuntos'
})
export class AssuntosPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): any {
    const obj = []
    if (value) {
     const dados = value.map(result => {
        if(result.faq_assunto === args[0]){
          obj.push(result)
        }
      })
    }
    return obj
  }
}
