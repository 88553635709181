<nav class="navbar navbar-expand-lg navbar-dark static-top" style="background-image: -webkit-linear-gradient( 88deg, rgb(27,143,200) 0%, rgb(23,133,212) 30%, rgb(19,122,223) 47%, rgb(19,104,218) 67%, rgb(19,85,213) 100%);;">
    <div class="container">
        <a class="navbar-brand" routerLink="/">

            <img height="45px" src="../assets/logo.png">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse menu" id="navbarResponsive">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item active">
                    <a class="nav-link" href="#">
                        <span class="sr-only">(current)</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/">Início</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="http://www.ipsm.mg.gov.br/servicos.asp?servicos=all" target="_blank">Serviços</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="http://ipsm.mg.gov.br/institucional.asp?institucional=telefones" target="_blank">Telefones Úteis</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="http://ipsm.mg.gov.br/conteudo.asp?pagina=fale_conosco" target="_blank">Fale Conosco</a>
                </li>
            </ul>
        </div>
    </div>
</nav>
<div class="busca" style="background-image:url('../assets/FAQ.png')">
    <div class="back" style=" background: rgba(135, 206, 235, 0.6);padding:30px 0 40px 0;">
        <h1 style="color: #6F6F6F; text-align: center;">Como o IPSM pode ajudar você?</h1>
        <div class="pesquisa center-me">
            <div class="conjunto-pesquisa" style="position: relative;">
                <input type="text" class="autocomplete" [(ngModel)]="pesquisa" (keyup)="buscar()" placeholder="Busque aqui" (keydown.enter)="search($event.target.value)">
                <button class="btn-pesquisar" (click)="resultados()">Pesquisar</button>
            </div>

            <ul class="complete" *ngIf="exibir_lista">
                <li class="opt" *ngFor="let r of resultado_pesquisa" (click)="selectFaq(r.faq_id, r.faq_titulo)">{{r.faq_titulo}} <span class="assunto">({{r.faq_assunto}})</span></li>
                <span *ngIf="not_found" style='color:red'>Desculpe, não conseguimos encontrar nenhum resultado.</span>
                <li class="close" (click)="fecharLista()">Fechar</li>
            </ul>
        </div>
    </div>
</div>
<div class="container">
    <div class="content">
        <router-outlet></router-outlet>
    </div>
</div>
<div class="footer">
    <div class="container">



        <div class="midias">
            <a href="http://instagram.com/ipsm.oficial" target="_blank"><i class="fa fa-instagram"></i></a>
            <a href="http://facebook.com/IpsmOficial" target="_blank"><i class="fa fa-facebook"></i></a>
            <a href="https://api.whatsapp.com/send?phone=5531982212355" target="_blank"><i class="fa fa-whatsapp"></i></a>
        </div>
        <div class="end">
            <b>Instituto de Previdência dos Servidores Militares do Estado de Minas Gerais - IPSM</b> <br>Rua Paraíba, 576 - Savassi - Belo Horizonte / MG - CEP: 30130-141 Telefone: (31) 3269-2000 - Horário de Atendimento: 08:00 às 17:30 hs
        </div>
    </div>
</div>
