import { Component } from '@angular/core';
import { FaqService } from '../services/faq.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';


@Component({
  selector: 'app-testando',
  templateUrl: './lista-resultado.html',
  styleUrls: ['./lista-resultado.css']
})


export class ListaResultado {
  public loading = false;
  api
  retorno
  data = [];
  searchText
  assunto
  host
  p: number = 1;
  termo


  isValid: boolean = true;
  changeValue(valid: boolean) {
    this.isValid = valid;
  }

  constructor(private service: FaqService, private route: ActivatedRoute, private router: Router) {

    this.loading = true
    // verifica os eventos da url e aciona o getFaq
    this.router.events.subscribe(val => {
      this.loading = true
      this.route.params.subscribe(params => {
        this.termo = params['termo'];
        console.log(this.termo)
        this.buscaResultado()
      });
    });
  }

  async buscaResultado() {
    this.retorno = await this.service.post(`${this.service.host}/faq/lista/resultados`, { termo: this.termo })
    console.log(this.retorno)
  }

  faq(id){
    this.router.navigate(['marca/' + id])
  }
}

