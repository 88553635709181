import { Component } from '@angular/core';
import { FaqService } from '../services/faq.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']

})
export class HomeComponent {
  public loading = false;
  api
  retorno = {
    geral: [],
    previdencia: [],
    saude: []
  }

  faq
  data = [];
  searchText
  novo_assunto = []


  constructor(private service: FaqService, private route: Router) {

    this.api = this.service.host + "/faq/receive";
    this.loading = true
    this.getFaq()
  }

  teste(id) {
    this.route.navigate(['marca/' + id])
  }

  async getFaq(): Promise<any> {

    // this.retorno = await this.service.post(this.api, { titulo: "1" })
    this.retorno.geral = await this.service.get(`${this.service.host}/faq/assunto/lista/Geral`)
    this.retorno.previdencia = await this.service.get(`${this.service.host}/faq/assunto/lista/Previdência`)
    this.retorno.saude = await this.service.get(`${this.service.host}/faq/assunto/lista/Saúde`)
    this.loading = false
  }

  keyword = 'faq_titulo';

  selectEvent(item) { }

  onChangeSearch(search: string) { }

  onFocused(e) { }

}
