import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ListaFaq } from './lista-faq/lista-faq';
import { AppRoutingModule } from './app.routing';
import { MarcaComponent } from './marca/marca.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GerarFAQComponent } from './gerar-faq/gerar-faq.component';
import { AssuntosPipe } from './pipes/assuntos.pipe';
import { CfaqPipe } from './pipes/cfaq.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxLoadingModule } from 'ngx-loading';
import {NgxPaginationModule} from 'ngx-pagination';
import { ListaResultado } from './lista-resultado/lista-resultado';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ListaFaq,
    ListaResultado,
    MarcaComponent,
    GerarFAQComponent,
    AssuntosPipe,
    CfaqPipe,
    FilterPipe



  ],
  imports: [
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    NgbModule,
    AutocompleteLibModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    NgxLoadingModule.forRoot({}),
    NgxPaginationModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }







